

















































































































































































































































































































































































































































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator';
import { AccountInfo } from "@azure/msal-browser";

import { RobotOption, TwitterCardOption } from '@/models/MetaTags';
import { getLocalesString } from '@/models/Locale';
import { GoodOrService, GoodsServicesQueryResult, GoodsServicesService, MasterDataItem, GoodOrServiceResultItem, BrandQueryResult, BrandQueriesService, BrandQuerySession, BrandQuery, CompletionResult, AiService, DomainsService, Domain } from '@/api/braendz';
import { BusyList, BusyObject } from '@/models/Busy';
import { getShortLocaleCode } from '@/models/Locale';
import { BrandStateCategory, Comparator, FilterableField, GoodsServicesQueryMode, SearchableField } from '@/models/Query';

import ImagePicker from '@/components/ImagePicker.vue';
import ScrollDownButton from '@/components/Buttons/ScrollDownButton.vue';
import ExplainButton from '@/components/Assistant/ExplainButton.vue';
import DomainChip from '@/components/Domains/DomainChip.vue';
import AddGoodOrServicePopup from '@/components/GoodsServices/AddGoodOrServicePopup.vue';
import VerticalBrandTileGrid from '@/components/Brands/VerticalBrandTileGrid.vue';
import BrandDetailsTextField from '@/components/Brands/BrandDetailsTextField.vue';
import TruncateTooltip from '@/components/TruncateTooltip.vue';
import ChatAside from '@/components/Assistant/ChatAside.vue';
import ExplainConflictButton from '@/components/Assistant/ExplainConflictButton.vue';
import { blobToBase64Async } from '@/models/Blob';

// Internally used types:
interface GroupedGoodOrServiceResultItems {
  niceClass?: MasterDataItem,
  resultItems: GoodOrServiceResultItem[]
}

interface GroupedGoodsServices {
  niceClass?: MasterDataItem,
  goodsServices: GoodOrService[]
}

@Component({
  components: {
    ImagePicker,
    ExplainButton,
    ExplainConflictButton,
    DomainChip,
    AddGoodOrServicePopup,
    VerticalBrandTileGrid,
    TruncateTooltip,
    BrandDetailsTextField,
    ChatAside,
    ScrollDownButton
  },
  metaInfo() {
    return {
      title: this.$i18n.t("new.title").toString(),
      meta: [
        { name: 'robots', content: [RobotOption.NoIndex, RobotOption.NoFollow].join(',') },

        // Open Graph: Facebook, Instagram, WhatsApp, LinkedIn, Xing, Twitter:
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: this.$i18n.t("new.metaTags.title").toString() },
        { property: 'og:description', content: this.$i18n.t("new.metaTags.description").toString() },
        { property: 'og:image', content: `${window.location.origin}${require('@/assets/logos/braendz-logo-tm-blue.png')}` },
        { property: 'og:locale', content: this.$i18n.locale },
        { property: 'og:locale:alternate', content: getLocalesString(',') },
        { property: 'og:site_name', content: this.$i18n.t("metaTags.title").toString()},

        // Twitter:
        { property: 'twitter:card', content: TwitterCardOption.SummaryLargeImage },
        { property: 'twitter:site', content: `@${process.env.VUE_APP_TWITTER_ACCOUNT}` }
      ]
    };
  }
})
export default class Create extends Vue {   
    // Fields
    public chatOpened = false;
    public chatInstance = "DesignAssistant";

    public name: string | null = null;
    public noName = false;
    public logo: Blob | null = null;
    public noLogo = false;
    public selectedRegistrationOfficeCodes: MasterDataItem[] = [];
    public selectedClassificationTab = 0;
    public selectedGoodsServices: GoodOrService[] = [];
    public selectedGoodsServicesOpenedPanels: number[] = [];
    public searchGoodsServicesKeywordInput = "";
    public goodsServicesKeywordQueryResult = new BusyObject<GoodsServicesQueryResult>();
    public goodsServicesUserDescription = '';
    public goodsServicesSemanticQueryResult = new BusyObject<GoodsServicesQueryResult>();
    public goodsServicesSemanticSearchResultOpenedPanels: number[] = [];
    public selectedBrandStateCategories: string[] = [BrandStateCategory.New, BrandStateCategory.Alive];
    
    public showAddGoodOrServicePopup = false;
    public showResults = false;

    public aiEvaluationBrandName = new BusyObject<CompletionResult>();
    public aiEvaluationRegistrationOfficeCodes = new BusyObject<CompletionResult>();
    public aiEvaluationGoodsServices = new BusyObject<CompletionResult>();
    public domainResults = new BusyList<Domain>();
    public brandQueryResult = new BusyObject<BrandQueryResult>();

    // Getter:
    public get userAccount(): AccountInfo | null {
        return this.$store.state.userAccount;
    }

    public get registrationOfficeCodes(): BusyList<MasterDataItem> {
      return this.$store.state.registrationOfficeCodes as BusyList<MasterDataItem>;
    }

    public setNoName(value: boolean) {
      if(value) this.noLogo = false;
      this.noName = value;
    }

    public setNoLogo(value: boolean) {
      if(value) this.noName = false;
      this.noLogo = value;
    }

    public get selectedBrandTypeKey(): 'Combined' | 'Word' | 'Figurative' | null {
      if(!this.noName && this.name && !this.noLogo && this.logo) {
        return 'Combined';
      }
      else if(!this.noName && this.name && this.noLogo) {
        return 'Word';
      }
      else if(this.noName && !this.noLogo && this.logo) {
        return 'Figurative';
      }
      return null;
    }

    public get selectedBrandType(): MasterDataItem | null {
      if(!this.selectedBrandTypeKey) return null;
      return (this.$store.getters.getBrandType(this.selectedBrandTypeKey) as MasterDataItem | undefined) ?? null;
    }

    public get step(): number {
      if(!this.selectedBrandTypeKey) {
        return 0;
      }
      else if(this.selectedRegistrationOfficeCodes.length <= 0) {
        return 1;
      }
      else if(this.selectedGoodsServices.length <= 0 || !this.showResults) {
        return 2;
      }
      else {
        return 3;
      }
    }

    public get selectedNiceClasses(): MasterDataItem[] {
      const result: MasterDataItem[] = [];
      for(const item of this.selectedGoodsServices) {
        if(!item.niceClass) continue;
        if(result.find(i => i.key === item.niceClass?.key)) continue;
        result.push(item.niceClass);
      }
      return result;
    }

    public get selectedGoodsServicesSorted(): GoodOrService[] {
      return this.selectedGoodsServices.sort((a, b) => {
        if(Number(a.niceClass?.key ?? 0) > Number(b.niceClass?.key ?? 0)) return 1;
        if(Number(a.niceClass?.key ?? 0) < Number(b.niceClass?.key ?? 0)) return -1;
        return 0;
      });
    }

    public get groupedGoodsServicesSemanticQueryResult(): GroupedGoodOrServiceResultItems[] {
      if(!this.goodsServicesSemanticQueryResult.object) return [];
      if(!this.goodsServicesSemanticQueryResult.object.items) return [];

      const result: GroupedGoodOrServiceResultItems[] = [];
      this.goodsServicesSemanticSearchResultOpenedPanels = []

      this.goodsServicesSemanticQueryResult.object.items.forEach(resultItem => {
        if(!resultItem) return;

        // Get existing nc-group:
        let existing = result.find(g => g.niceClass?.key === resultItem.indexItem?.niceClass?.key)

        // Add a new item or push a new group
        if(existing) {
          existing.resultItems.push(resultItem)
        } else {
          const group = { niceClass: resultItem.indexItem?.niceClass ?? undefined, resultItems: [ resultItem ]};
          result.push(group);
          this.goodsServicesSemanticSearchResultOpenedPanels.push(result.indexOf(group));
        }
      });

      return result;
    }

    public get groupedSelectedGoodsServices(): GroupedGoodsServices[] {
      const result: GroupedGoodsServices[] = [];
      this.selectedGoodsServicesOpenedPanels = []

      this.selectedGoodsServicesSorted.forEach(item => {

        // Get existing nc-group:
        let existing = result.find(g => g.niceClass?.key === item.niceClass?.key)

        // Add a new item or push a new group
        if(existing) {
          existing.goodsServices.push(item)
        } else {
          const group = { niceClass: item.niceClass ?? undefined, goodsServices: [ item ]};
          result.push(group);
          this.selectedGoodsServicesOpenedPanels.push(result.indexOf(group));
        }
      });

      return result;
    }

    public get goodsServicesAutocompleteItems(): GoodOrService[] {
      const result: GoodOrService[] = [];

      if(this.goodsServicesKeywordQueryResult.object && this.goodsServicesKeywordQueryResult.object.items) {
        for(const item of this.goodsServicesKeywordQueryResult.object.items) {
          if(!item.indexItem) continue;
          result.push(item.indexItem);
        }
      }

      if(this.goodsServicesSemanticQueryResult.object && this.goodsServicesSemanticQueryResult.object.items) {
        for(const item of this.goodsServicesSemanticQueryResult.object.items) {
          if(!item.indexItem) continue;
          if(result.find(i => i.id === item.indexItem?.id)) continue;

          result.push(item.indexItem);
        }
      }
      
      return result;
    }

    public get configuredBrand() {
      return {
        name: this.name,
        brandType: this.selectedBrandType,
        registrationOfficeCodes: this.selectedRegistrationOfficeCodes,
        goodsServices: this.selectedGoodsServicesSorted
      }
    }

    public get logoUrl(): string | null {
        if (!this.logo) return null;
        return URL.createObjectURL(this.logo);
    }

    // Watchers & Event Handlers:
    @Watch('searchGoodsServicesKeywordInput')
    public onSearchGoodsServicesInputSimpleChanged() {
      this.queryGoodsServicesByKeywords(this.searchGoodsServicesKeywordInput);
    }

    // Component Lifecycle
    public mounted(): void {
        // Initialize some stuff here:
        this.$store.dispatch("updateMasterData");
    }

    // Methods
    public filterNiceClasses(item: MasterDataItem, queryText: string): boolean {
        return (
            (item.key?.toLocaleLowerCase()?.indexOf(queryText.toLocaleLowerCase()) ?? -1) > -1 ||
            (item.shortDescription?.toLocaleLowerCase()?.indexOf(queryText.toLocaleLowerCase()) ?? -1) > -1 ||
            (item.description?.toLocaleLowerCase()?.indexOf(queryText.toLocaleLowerCase()) ?? -1) > -1
        );
    }

    public removeSelectedGoodOrService(item: GoodOrService) {
      const index = this.selectedGoodsServices.indexOf(item);
      if(index >= 0) this.selectedGoodsServices.splice(index, 1);
    }

    public addGoodOrService(item: GoodOrService) {
      this.selectedGoodsServices.push(item);
    }

    public async queryGoodsServicesByKeywords(keyword: string): Promise<void> {
      if(this.goodsServicesKeywordQueryResult.isBusy) return;
      if(!keyword) return;
      if(keyword.length < 3) return;

      await this.goodsServicesKeywordQueryResult.load(async () => {
        return await GoodsServicesService.executeGoodsServicesQuery({ mode: GoodsServicesQueryMode.KeywordSearchLucene, text: keyword, filters: [{ field: FilterableField.LanguageCode, comparator: Comparator.Equals, value: getShortLocaleCode(this.$i18n.locale) }] });
      });
    }

    public async queryGoodsServicesByVector(): Promise<void> {
      await this.goodsServicesSemanticQueryResult.load(async () => {
        return await GoodsServicesService.executeGoodsServicesQuery({ mode: GoodsServicesQueryMode.VectorSearch, text: this.goodsServicesUserDescription, filters: [{ field: FilterableField.LanguageCode, comparator: Comparator.Equals, value: getShortLocaleCode(this.$i18n.locale) }], size: 100 });
      });
    }

    public updateResults(): void {
      this.updateAiEvaluationBrandName();
      this.updateAiEvaluationRegistrationOfficeCodes();
      this.updateAiEvaluationGoodsServices();
      this.updateDomains();
      this.updateBrandQueryResult();
      this.showResults = true;
    }

    public async updateAiEvaluationBrandName(): Promise<void> {
      await this.aiEvaluationBrandName.create(async () => {
        if(this.noName || !this.name) return null;

        return await AiService.executeCompletion({
          instruction: `You are an intelligent brand assistant dedicated to guiding users through the trademark registration process.\n\nThe user has configured a brand they may want to register. Here is the brand's configuration: ${JSON.stringify(this.configuredBrand)}\n\nPlease answer the user's questions concisely, and do not recommend further research.\n\nRespond in the language and cultural context of '${this.$i18n.locale}' and format your response using HTML tags without headlines (e.g., avoid <h1>, <h2> etc.).`,
          userPrompt: `Does the brand name I'm using create a positive impact in relation to what the brand represents? If applicable, does the name have any negative connotations in other countries or languages?`
        });
      });
    }

    public async updateAiEvaluationRegistrationOfficeCodes(): Promise<void> {
      await this.aiEvaluationRegistrationOfficeCodes.create(async () => {
        return await AiService.executeCompletion({
          instruction: `You are an intelligent brand assistant dedicated to guiding users through the trademark registration process.\n\nThe user has configured a brand they may want to register. Here is the brand's configuration: ${JSON.stringify(this.configuredBrand)}\n\nPlease answer the user's questions concisely, and do not recommend further research.\n\nRespond in the language and cultural context of '${this.$i18n.locale}' and format your response using HTML tags without headlines (e.g., avoid <h1>, <h2> etc.).`,
          userPrompt: `Are there any specific considerations or special requirements I should be aware of when registering my brand with the selected registration offices (RegistrationOfficeCodes)? Please highlight any unique rules or processes that apply to any of the offices.`
        });
      });
    }

    public async updateAiEvaluationGoodsServices(): Promise<void> {
      await this.aiEvaluationGoodsServices.create(async () => {

        const instruction = this.goodsServicesUserDescription ?
          `You are an intelligent brand assistant dedicated to guiding users through the trademark registration process.\n\nThe user has configured a brand they may want to register. Here is the brand's configuration: ${JSON.stringify(this.configuredBrand)}\n\nThe user has described the goods and services of their brand in free text as follows: '${this.goodsServicesUserDescription}'\n\nPlease answer the user's questions concisely, and do not recommend further research.\n\nRespond in the language and cultural context of '${this.$i18n.locale}' and format your response using HTML tags without headlines (e.g., avoid <h1>, <h2> etc.).`
          : `You are an intelligent brand assistant dedicated to guiding users through the trademark registration process.\n\nThe user has configured a brand they may want to register. Here is the brand's configuration: ${JSON.stringify(this.configuredBrand)}\n\nPlease answer the user's questions concisely, and do not recommend further research.\n\nRespond in the language and cultural context of '${this.$i18n.locale}' and format your response using HTML tags without headlines (e.g., avoid <h1>, <h2> etc.).`

        return await AiService.executeCompletion({
          instruction: instruction,
          userPrompt: `Are the selected GoodsServices and NiceClasses sufficient to protect my brand? If there are any areas for improvement or additional categories that would enhance protection, please suggest them.`
        });
      });
    }

    public async updateDomains(): Promise<void> {
      await this.domainResults.load(async () => {
        if(this.noName || !this.name) return null;
        
        const tldTags: string[] = this.selectedRegistrationOfficeCodes.map(mdi => mdi.key);
        tldTags.push('Common');
        
        return await DomainsService.getDomains({
          name: this.name,
          tldTags: tldTags
        });
      });
    }

    public async updateBrandQueryResult(): Promise<void> {

      await this.brandQueryResult.create(async () => {

        if(!this.noName && !this.name) return null;
        if(!this.noLogo && !this.logo) return null;

        const brandQuerySession = await this.createQuerySession(
          this.brandQueryResult?.object?.sessionId ?? null,
          this.noName ? null : this.name,
          this.noLogo ? null : this.logo,
          this.selectedRegistrationOfficeCodes.map(i => i.key),
          this.selectedBrandType?.key ?? null,
          this.selectedNiceClasses.map(i => i.key),
          this.selectedBrandStateCategories,
        );

        return await BrandQueriesService.executeBrandQuery(brandQuerySession);

      });
    }

    public async createQuerySession(sessionId: string | null, name: string | null, logo: Blob | null, registrationOfficeCodes: string[], brandType: string | null, niceClasses: string[], brandStateCategories: string[]): Promise<BrandQuerySession> {
      const brandQuery = {
        size: 20,
        skip: 0,
        filters: [],
        facets: []
      } as BrandQuery;

      if(name) {
        brandQuery.terms = [{ field: SearchableField.Name, value: name }]
      }

      if(logo) {
        brandQuery.logo = await blobToBase64Async(logo);
      }

      // Configure filters:
      for(const registrationOfficeCode of registrationOfficeCodes) {
        brandQuery.filters?.push({ field: FilterableField.RegistrationOfficeCode, value: registrationOfficeCode, comparator: Comparator.Equals })
      }

      if(brandType) {
        // brandQuery.filters?.push({ field: FilterableField.BrandType, value: brandType, comparator: Comparator.Equals })

        // If brand type is figurative, just filter for figurative trademarks.
        if(brandType === 'Figurative') {
          brandQuery.filters?.push({ field: FilterableField.BrandType, value: 'Figurative', comparator: Comparator.Equals })
        }
        else {
          brandQuery.filters?.push({ field: FilterableField.BrandType, value: 'Combined', comparator: Comparator.Equals })
          brandQuery.filters?.push({ field: FilterableField.BrandType, value: 'Word', comparator: Comparator.Equals })
        }
      }

      for(const niceClass of niceClasses) {
        brandQuery.filters?.push({ field: FilterableField.NiceClasses, value: niceClass, comparator: Comparator.Equals })
      }

      for(const brandStateCategory of brandStateCategories) {
        brandQuery.filters?.push({ field: FilterableField.BrandStateCategory, value: brandStateCategory, comparator: Comparator.Equals })
      }

      return { 
        id: sessionId, 
        query: brandQuery 
      } as BrandQuerySession;
  }
}
