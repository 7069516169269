
















































































import Vue from 'vue'
import VMenu from 'vuetify'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { GoodOrService, MasterDataItem } from '@/api/braendz';
import { Guid } from 'guid-typescript';

@Component({
    components: {
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class AddGoodOrServicePopup extends Vue {
    // Member
    public valid = false;
    public selectedNiceClass: MasterDataItem | null = null;
    public goodOrServiceDescription = "";
        
    // Properties
    @Prop({ required: true })
    public model!: boolean;

    // Getter & Setter
    public get visible(): boolean {
        return this.model;
    }
    public set visible(value: boolean) {
        this.fireVisibilityChanged(value);
    }

    public get niceClasses(): MasterDataItem[] {
        return this.$store.state.niceClasses.list as MasterDataItem[];
    }
    
    // Watcher:
    @Watch('model')
    public onModelChanged(value: boolean) {
        if(value) {
            this.reset();
        }
    }

    // Lifecycle Methods:
    public mounted() {
        this.reset();
    }

    // Methods:

    
    public reset(): void {
        this.selectedNiceClass = null;
        this.goodOrServiceDescription = "";
    }

    public close(): void {
        this.visible = false;
    }

    public add(): void {
        if (!((this.$refs.form as Vue & { validate: () => boolean }).validate())) {
            return;
        }

        this.fireAdded({ id: Guid.create().toString(), niceClass: this.selectedNiceClass, description: this.goodOrServiceDescription });
        this.close();
    }

    public fireVisibilityChanged(value: boolean): void {
        this.$emit('visibilityChanged', value);
    }

    public fireAdded(value: GoodOrService): void {
        this.$emit('added', value);
    }
}
